<template>
  <nav class="c-lnb">
    <div class="c-lnb__list">
      <router-link
        v-for="(route, index) in routes"
        :key="index"
        :to="route.path"
        active-class="bg-[#007f70]"
        class="c-lnb__item"
      >
        <i :class="route.iconClass"></i>
        <span class="c-lnb__tooltip">{{ route.tooltip }}</span>
      </router-link>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { roleStore } from '@/stores/role';

import { projectStore } from '@/stores/project';

import { $t } from '@/plugins/translation';

const roleModule = roleStore();
const projectModule = projectStore();
const selectedProjectIdx = computed(() => projectModule.selectedProjectId || 0);

const routes = computed(() => [
  { path: '/', iconClass: 'c-lnb__icon', tooltip: $t('common', 'home') },
  {
    path: `/dashboard/${selectedProjectIdx.value}`,
    iconClass: 'c-lnb__icon c-lnb__icon--dashboard',
    tooltip: $t('component.header', 'dashboard'),
  },
  {
    path: `/timeline/${selectedProjectIdx.value}`,
    iconClass: 'c-lnb__icon c-lnb__icon--calendar',
    tooltip: $t('component.header', 'timeline'),
  },
  {
    path: calculateRoute.value,
    iconClass: 'c-lnb__icon c-lnb__icon--point',
    tooltip: $t('component.header', 'settlement'),
  },
  {
    path: `/setting`,
    iconClass: 'c-lnb__icon c-lnb__icon--setting',
    tooltip: $t('common', 'setting'),
  },
]);
const calculateRoute = computed(() => {
  if (roleModule.isClient) {
    return `/calculate/client/${selectedProjectIdx.value}`;
  } else {
    return `/calculate/expert/${selectedProjectIdx.value}`;
  }
});
</script>

<style lang="scss">
.c-lnb {
  $this: '.c-lnb';
  grid-area: nav;
  width: 8rem;
  height: 100vh;
  padding: 10px;
  background: var(--main-50);
  &__list {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    width: 6rem;
    height: 6rem;
    cursor: pointer;
    border-radius: var(--radius-4);
    position: relative;
    &:hover {
      background: var(--main-30);
      #{$this}__tooltip {
        display: block;
      }
    }
    &:focus {
      background: var(--main-30);
    }
  }
  &__icon {
    display: inline-flex;
    width: 2rem;
    height: 2rem;
    background: url(@/assets/images/icon_hamberger.svg) center / contain no-repeat;
    &--dashboard {
      background: url(@/assets/images/icon_dashboard.svg) center / contain no-repeat;
    }
    &--calendar {
      background: url(@/assets/images/icon_calendar_white.svg) center / contain no-repeat;
    }
    &--point {
      background: url(@/assets/images/icon_point.svg) center / contain no-repeat;
    }
    &--setting {
      background: url(@/assets/images/icon_set.svg) center / contain no-repeat;
    }
  }
  &__tooltip {
    position: absolute;
    top: 50%;
    left: 110%;
    width: 8rem;
    height: 3rem;
    line-height: 3rem;
    transform: translateY(-50%);
    background: #000;
    color: var(--white);
    text-align: center;
    font-size: var(--font-14);
    border-radius: 0.2rem;
    z-index: 110;
    display: none;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent #000 transparent transparent;
    }
    &:hover {
      .c-tooltip__txt {
        visibility: visible;
      }
    }
  }
}
</style>
