export const dateFormat = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${year}${month < 10 ? '0' + month : month}${day < 10 ? '0' + day : day}`;
};
export const dateInput = (date: string) => {
  if (!date) return '';
  if (date.length == 6) {
    return `${date.substring(0, 4)}.${date.substring(4, 6)}`;
  } else if (date.length > 6) {
    return `${date.substring(0, 4)}.${date.substring(4, 6)}.${date.substring(6, 8)}`;
  }
  return date;
};

export const resizeImage = async (
  file: File,
  maxWidth: number,
  maxHeight: number,
  type: string,
  quality: number,
): Promise<File> => {
  const loadImage = (src: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  };
  const img = await loadImage(URL.createObjectURL(file));
  const canvas = document.createElement('canvas');
  let width = img.width;
  let height = img.height;
  if (width > height) {
    if (width > maxWidth) {
      width = maxWidth;
      height = Math.round(height * (maxWidth / width));
    }
  } else {
    if (height > maxHeight) {
      width = Math.round(width * (maxHeight / height));
      height = maxHeight;
    }
  }
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');

  if (!ctx) throw new Error('이미지 변환 실패');

  ctx.drawImage(img, 0, 0, width, height);
  const blob = await new Promise<Blob | null>((resolve) =>
    canvas.toBlob(resolve, type, quality / 100),
  );
  if (!blob) throw new Error('이미지 변환 실패');

  const newFile = new File([blob], file.name, { type: blob.type });

  return newFile;
};
export const monthFormat = (date: Date) => {
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${year}${month < 10 ? '0' + month : month}`;
};
export const paymentFormat = (payment: number, numberDecimal = 0) => {
  if (!payment) return 0;

  const pay = Number(payment).toFixed(numberDecimal).toString();
  const decimalIndex = pay.indexOf('.');
  let integerPart = pay;
  if (decimalIndex !== -1) {
    integerPart = pay.slice(0, decimalIndex);
  }
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  let decimalPart = '';
  if (decimalIndex !== -1) {
    decimalPart = pay.slice(decimalIndex);
  }
  return integerPart + decimalPart;
};
export const backgroundColor = (colorCode: string) => {
  switch (colorCode) {
    case 'green':
      return 'var(--person-10)';
    case 'skyblue':
      return 'var(--person-20)';
    case 'purple':
      return 'var(--person-30)';
    case 'yellow':
      return 'var(--person-40)';
    case 'red':
      return 'var(--person-50)';
    case 'blue':
      return 'var(--person-60)';
    case 'orange':
      return 'var(--person-70)';
    case 'black':
      return 'var(--person-80)';
    default:
      return 'var(--gray-20)';
  }
};

export const getAvatarUri = (uri: string) =>
  uri ? { uri: uri } : require('@/assets/images/icon_profile_dummy.png');

export const ensureHttps = (url: string) => {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return 'https://' + url;
  }
  return url;
};
