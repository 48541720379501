import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import { userStore } from '@/stores/authStore';
import guide from '../router/guide';
import home from '../router/home';
import login from '../router/login';
import join from '../router/join';
import profile from './profile';
import dashboard from '../router/dashboard';
import setting from '../router/setting';
import timeline from '../router/timeline';
import find from '../router/find';
import calculate from './calculate';
import middleware from './middleware';
import verify from './verify';
import document from './document';

// import home from "@/router/home";

const routes = [
  {
    path: '/',
    // component:()=> () => import('@/views/Home.vue'),
    name: 'home',
    // component: () => import('@/views/PageList.vue'),
    component: () => import('@/views/Main.vue'),
    meta: {
      type: 'home',
    },
  },
  ...guide,
  ...home,
  ...login,
  ...join,
  ...profile,
  ...dashboard,
  ...setting,
  ...timeline,
  ...find,
  ...calculate,
  ...middleware,
  ...verify,
  ...document,
  // ...home,
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
});

interface Context {
  next: () => void;
  [key: string]: any;
}

type Middleware = (context: Context) => void;

const nextFactory = (context: Context, middleware: Middleware[], index: number) => {
  const nextMiddleware = middleware[index];

  if (!nextMiddleware) {
    return context.next;
  }

  const subsequentMiddleware = nextFactory(context, middleware, index + 1);
  return () => nextMiddleware({ ...context, next: subsequentMiddleware });
};

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }

  const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];
  const context = {
    to,
    from,
    next,
    router,
  };

  return middleware[0]({ ...context, next: nextFactory(context, middleware, 1) });
});

export default router;
