export interface ProjectPayment {
  subscriptionType: string;
  sheets: number;
  nextPaymentDate: string;
  planType: SubscriptionType;
}

export interface PaymentHistory {
  paymentDate: string;
  sheets: number;
  paymentType: string;
  totalPrice: string;
  createdAt: string;
  typeAsRecord: string;
  paymentIdx: number;
}

export interface RefundRecord {
  paymentIdx: number;
  refundDate: string;
  refundPercent: number;
  sheets: number;
  totalPrice: number;
  type: RefundType;
  projectIdx: number;
  plan: SubscriptionType;
  usetime: number;
  createdAt: string;
  id: number;
}

export interface PayAndRefundHistory {
  paymentDate?: string;
  sheets?: number;
  paymentType?: string;
  totalPrice?: string;
  refundDate?: string;
  projectIdx?: number;
  plan?: SubscriptionType;
  useTime?: any;
  refundPercent?: number;
}

export enum RefundType {
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
}

export enum SubscriptionType {
  MONTH = '30D',
  YEAR = '12M',
  FREE = 'FREE',
}

export const CurrentPaymentDesc: Record<SubscriptionType, string> = {
  [SubscriptionType.MONTH]: 'usingMonthly',
  [SubscriptionType.YEAR]: 'usingYearly',
  [SubscriptionType.FREE]: 'usingForFree',
};

export const PlanType: Record<SubscriptionType, string> = {
  [SubscriptionType.MONTH]: '월 구독',
  [SubscriptionType.YEAR]: '연 구독',
  [SubscriptionType.FREE]: '무료 이용',
};

export const PaymentType: Record<SubscriptionType, string> = {
  [SubscriptionType.MONTH]: '월 결제',
  [SubscriptionType.YEAR]: '연 결제',
  [SubscriptionType.FREE]: '무료 이용',
};

export interface PlanDetail {
  planIdx: number;
  name: string;
  price: number;
  type: SubscriptionType;
  duration: number;
}

export interface Subscription {
  sheets: number;
  duration: string;
  price: number;
  paymentType: string;
  planType: string;
  planCode: string;
  startDate: string;
  endDate: string;
  amount: number;
  payments: CurrentSubPayment[];
}

export interface CurrentSubPayment {
  paymentIdx: number; // as a reference to transactionId
  paymentDate: string;
  initialSheets: number;
  leftSheets: number;
  initialAmount: number;
  leftAmount: number;
  leftPercent: number;
}

// information for each previous indivisual payment
export interface RefundDataForCal {
  paymentDate: string;
  paymentAmount: number;
  paymentLeftSheets: number;
  paidSheets: number;
  sheetsToRefundCounter: number;
}

export interface PreviousPayment {
  initialAmount: number;
  paymentDate: string;
  leftSheets: number;
  initialSheets: number;
  paymentIdx: number;
}

// information for each row in the refund preview table
// this includes previous data and current refund information
export interface RefundPreview {
  date: string;
  sheets: string;
  type: string;
  amount: string | number;
  isRefund: boolean;
}

export enum UpdateSubscriptionAmount {
  ADD = 'ADD',
  SUB = 'SUB',
}

// information for a possible refund for each matching previous payment
export interface CurrentRefund {
  paymentIdx: number;
  refundAmount: number;
  refundAmountPercent: number;
  refundSheets: number;
}

export enum MaximumMembersForOneProject {
  DEFAULT = 300,
}

export enum FreeSheetsNumber {
  DEFAULT = 4,
}
